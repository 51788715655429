<template>
    <div>
      <v-card v-if="!sItem.dialog">
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        ><span class="text-h6">ナビのコピー</span>
        </v-toolbar>
        <v-card-text>
          <v-container class="pa-0 ma-0">
            <v-img
              :src=" (status) ? img : altImg " 
              v-on:error="status=false"  
              max-height="300"
              :aspect-ratop="16/9" 
            > 
            </v-img>

             <v-row
                class="text-center my-1 mx-0"
                height="70"
              >
              <v-col cols=12 class="ma-0 mt-1 pa-0">公開設定、表示回数は初期値に設定されます。</v-col> 
              <v-col v-if="!checkOverSize" cols=12 class="my-1 pa-0 red--text font-weight-bold">容量制限を超えるためコピーできません。</v-col>
              </v-row>
          </v-container>
        </v-card-text>
         
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2"
              outlined
              color="#78909C"   
              @click="close">キャンセル
          </v-btn>
          <v-btn 
            color="#1DE9B6"   
            class="white--text"
            :disabled="!checkOverSize"
            @click="copyNavi"
            >コピー
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card  v-else >
        <DialogStatus
          v-if="sItem.type"
          :item="sItem"
          v-on:closeEmit="close"
        />
      </v-card>
     
       
    </div>
      

</template> 

<script>

// import DialogError from './DialogError.vue'
import axios from "axios"
const DialogStatus = () => import('@/components/DialogStatus.vue');
export default {
  name: 'DialogCopyNavi',
  components:{
    DialogStatus
  },
  props: ["item"],
  data () {
    return {
      img:this.item.thumbnail,
      status:true,
      sItem:{
        dialog:false,
        type:"",
        text:`通信中...`,
        loadText:"通信中...",
        compText:"ナビのコピーが完了しました。",
        errorText:"エラーが発生しました。",
        close:true,
      },
      altImg:require("@/assets/navi.jpg"),
   
    }
  },

  watch:{
    item:{
      handler(newVal){
        this.img = newVal.thumbnail;
      },
      deep: true,
    },

  },

  computed:{
    checkOverSize(){
      let storage = this.$store.state.user_model.userData['navi'];
      return storage.usage + 1 <= storage.limit;  
    }
  },


  methods: {

      copyNavi: async function(){
        this.sItem.dialog = true;
        this.sItem.type = "load";
        await axios({
          method: "POST",
          url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/${this.item.naviId}`,
          headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
        }).then((response) => {
          this.$emit("copyNaviEmit",response.data.results);
          this.sItem.type = "comp";
        }).catch(reason => {
          console.log(reason.response)
          this.sItem.type = "error";
          this.sItem.errorText = reason.response.data.message;
        });
    },

    //画像の読み込み
    getBase64 (file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
      })
    },

    close (){
      this.sItem.dialog = false;
      this.img = null;
      this.$emit('closeEmit')
    }
 
  },
}
 

</script>
